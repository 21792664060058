<template>
  <div class="locations">
    <v-row class="w-100">
      <v-col cols="12" :md="12">
        <!-- table -->
        <div class="list-header">
          <div
            class="list-head d-flex w-100 justify-space-between align-center px-6 pt-5"
          >
            <span>
              <p class="gray--text mr-4 mt-1">
                Locations ({{ listItems.length }})
              </p>
            </span>
          </div>
          <div class="pa-4 px-7">
            <div
              class="py-2 text-center radius-5 secondary cursor-pointer"
              @click="addNew"
            >
              <span class="white--text font-16">Add New Location</span>
            </div>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="listItems"
          :item-class="
            (item) => {
              return 'cursor-pointer';
            }
          "
          class="locations-table"
          height="calc(100vh - 140px)"
          :page.sync="page"
          mobile-breakpoint="0"
          @click:row="viewItem"
          hide-default-footer
          fixed-header
        >
          <template v-slot:item.icon="{ item }">
            <div>
              <img
                v-if="item.icon"
                :src="item.icon?.url"
                class="mt-2"
                style="width: 30px; height: 30px"
              />
            </div>
          </template>
          <template v-slot:item.delete="{ item }">
            <v-icon
              class="cursor-pointer"
              color="error"
              @click="deleteConfirm(item, $event)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
      <v-dialog
        v-model="locationDialog"
        content-class="white px-6 py-7"
        max-width="500"
      >
        <location-dialog
          :key="locationDialog"
          @close="locationDialog = false"
          :paramID="paramID"
          @reloadData="reloadData"
        ></location-dialog>
      </v-dialog>
    </v-row>
    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
import LocationDialog from "@/components/dialogs/Location.vue";
export default {
  components: {
    LocationDialog
  },
  data: () => ({
    newStatDialog: false,
    total: 0,
    paramID: "",
    locationDialog: false,
    categories: [],
    obj: {
      title: {},
      icon: undefined,
      inputType: undefined
    },
    valid: false,
    item: undefined,
    listItems: [],
    headers: [
      { text: "Icon", value: "icon", width: "75px" },
      { text: "Title", value: "title" },
      { text: "Actions", value: "delete", width: "85px", sortable: false }
    ],
    deleteDialog: false,
    deleteItem: "",
    page: 1
  }),
  methods: {
    viewItem(item) {
      this.paramID = item._id;
      this.locationDialog = true;
    },
    addNewSelectHandler() {
      this.obj.selections.push({
        value: {},
        icon: undefined,
        id: Math.random()
      });
    },
    removeSelectHandler(event) {
      if (event._id) {
        this.obj.selections = [
          ...this.obj.selections.filter((i) => i._id != event._id)
        ];
      } else {
        this.obj.selections = [
          ...this.obj.selections.filter((i) => i.id != event.id)
        ];
      }
    },
    deleteConfirm(item, event) {
      this.deleteDialog = true;
      this.deleteItem = item;
      event.stopPropagation();
    },
    async deleteHandler() {
      const res = await this.$axios.delete(`/location/${this.deleteItem._id}`);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        await this.fetchList();
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    addNew() {
      this.paramID = undefined;
      this.locationDialog = true;
    },
    async fetchList() {
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      let { data } = await this.$axios.get(`location`);
      if (data) {
        this.listItems = data.locations;
        this.total = data.count;
      }
    },
    async reloadData() {
      await this.fetchList();
    }
  },
  async created() {
    await this.fetchList();
  }
};
</script>

<style lang="scss" scoped>
.locations {
  height: 100vh;
  overflow: hidden;
  .activity-cover {
    background: white;
  }
  .locations-table {
    overflow: auto;
  }
  .list-header {
    height: 140px;
  }
}
</style>
