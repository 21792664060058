<template>
  <div>
    <div class="d-flex align-center justify-space-between b-bottom mb-6">
      <span class="d-block font-16 font-500">Location</span>
      <base-select
        v-model="language"
        :items="$root.languages"
        placeholder="Language"
        :returnObject="false"
      ></base-select>
      <v-icon @click="close" size="20" color="primary">mdi-close</v-icon>
    </div>
    <v-form v-model="valid" @submit.prevent="submitHandler" ref="locationform">
      <div class="px-6 mt-4 mb-10">
        <v-row>
          <!-- title -->
          <v-col cols="12" sm="6" md="4" class="mt-0 pt-0 pb-0">
            <label class="font-13 grey--text">Location Title</label>
            <base-input
              placeholder="Location Title"
              v-model="obj.title[language]"
              :hideDetails="false"
            ></base-input>
          </v-col>

          <!-- upload icon  -->
          <v-col cols="5" md="3" class="py-0">
            <!-- upload new -->
            <label class="font-13 grey--text mt-0">Main Icon</label>
            <div class="upload-item whitebg">
              <div>
                <v-icon v-if="!obj.icon" color="grayicon"
                  >mdi-folder-multiple-image</v-icon
                >
                <img v-if="obj.icon" :src="obj.icon" class="grayicon" />
              </div>
              <v-file-input
                :key="obj.icon && obj.icon.name ? obj.icon.name : obj.icon"
                @change="uploadIcon"
                hide-details="true"
                :rules="[
                  () => {
                    return obj.icon != undefined;
                  }
                ]"
              ></v-file-input>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-col class="d-flex" cols="12">
        <!-- buttons -->
        <buttons-experience
          @cancel="close"
          @next="submitHandler"
          text="Submit"
          :isValid="valid"
          :key="valid"
        ></buttons-experience>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  props: {
    paramID: {
      type: String,
      default: undefined
    }
  },
  components: {
    ButtonsExperience
  },
  data: () => ({
    obj: { title: {} },
    valid: false,
    language: "en"
  }),
  methods: {
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(`/location/${this.paramID}`);
        if (data && data.location) {
          const item = data.location;
          this.obj = {
            title: item.title,
            icon: item.icon?.url,
            _id: item._id
          };
        }
      }
    },
    async submitHandler() {
      //   send request
      let formData = new FormData();
      if (this.obj.newIcon) {
        formData.append("icon", this.obj.newIcon);
      }
      formData.append("title", JSON.stringify(this.obj.title));

      var res;
      if (this.obj._id) {
        res = await this.$axios.put("location/" + this.obj._id, formData);
      } else {
        res = await this.$axios.post("location", formData);
      }
      if (res.data) {
        this.$emit("close");
        this.$emit("reloadData");
        this.$store.dispatch("showSnack", {
          text: `Updated successfully`,
          color: "success"
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error"
        });
      }
    },
    async uploadIcon(file) {
      this.obj.icon = URL.createObjectURL(file);
      this.obj.newIcon = file;
    },
    close() {
      this.$emit("close");
    }
  },
  async created() {
    this.language = this.$root.language;
    await this.fetchItemByID();
  }
};
</script>
